<template>
  <Modal
    v-model="show"
    class="modal"
    :class="fullClass"
    :fullscreen="fullScreen"
    :footer-hide="true"
    @on-cancel="cancelHandle"
    :mask-closable="false"
    :draggable="true"
    width=""
    :styles="{left: '200px'}"
  >
    <div slot="header">
      <span>{{ info.name }}</span>
    </div>
    <div v-if="show" class="content">
      <!--仪表盘-->
      <div
        class="db-icon pointer"
        v-show="!hideDashIcon && info.IsPublish"
        v-if="showDashBoard"
        @click.stop="hideDashBoard"
      >
        <img :src="icons.dashboardfold" />
      </div>
      <div class="dash-board" :class="closeDash ? 'hideDash' : ''">
        <div class="db-inner">
          <img
            :src="icons.dashboardfold"
            alt
            class="db-fold pointer"
            v-show="!closeDash"
            @click.stop="hideDashBoard"
          />
          <!-- 仪表盘 -->
          <div class="title">
            <div class="text">实时仪表盘</div>
            <!-- <img :src="icons.closeDash" alt @click="hideDashBoard" /> -->
          </div>
          <div class="parameter">
            <!-- 无人机型号 -->
            <div class="DRONE">
              <img
                src="@/assets/img/statistics/Phantom 4 RTK.png"
                v-if="info.DRONEMODEL == 'Phantom 4 RTK'"
              />
              <img
                src="@/assets/img/statistics/M210 RTK V2.png"
                v-if="info.DRONEMODEL == 'M210 RTK V2'"
              />
              <img
                src="@/assets/img/statistics/Mavic 2 Enterprise.png"
                v-if="info.DRONEMODEL == 'Mavic 2 Enterprise'"
              />
              <img
                src="@/assets/img/statistics/Mavic 2 Enterprise Dual.png"
                v-if="info.DRONEMODEL == 'Mavic 2 Enterprise Dual'"
              />
              <img
                src="@/assets/img/statistics/Phantom 4 Pro.png"
                v-if="info.DRONEMODEL == 'Phantom 4 Pro'"
              />
              <img
                src="@/assets/img/statistics/Matrice 300 RTK.png"
                v-if="info.DRONEMODEL == 'Matrice 300 RTK'"
              />
              <img
                src="@/assets/img/statistics/M210 V2.png"
                v-if="info.DRONEMODEL == 'M210 V2'"
              />
              <span>{{ info.DRONEMODEL }}</span>
            </div>
            <!-- 无人机参数 -->
            <div class="board">
              <div>
                <span>高度</span>
                <p>
                  {{
                    (Math.floor(info.DroneAltitude * 100) / 100)
                      | format1Decimal
                  }}m
                </p>
              </div>
              <div>
                <span>速度</span>
                <p>{{ info.speed | format1Decimal }}m/s</p>
              </div>
              <div>
                <span>云台水平角</span>
                <p>{{ info.gimbalYaw | formatAngle0 }}</p>
              </div>
              <div>
                <span>云台俯仰角</span>
                <p>{{ info.gimbalPitch | formatAngle0 }}</p>
              </div>
              <div>
                <span>遥控信号</span>
                <p>{{ info.remote | formatPercent }}</p>
              </div>
              <div>
                <span>图传信号</span>
                <p>{{ info.figure_pass | formatPercent }}</p>
              </div>
              <div>
                <span>遥控电量</span>
                <p>{{ info.remote_power | formatPercent }}</p>
              </div>
              <div>
                <span>无人机电量</span>
                <p>{{ info.power | formatPercent }}</p>
              </div>
            </div>
            <div class="location">
              <span style="margin-right: 54px">
                <span class="label">经度</span>
                <span class="data">{{
                  info.DroneLongitude | formatPosition
                }}</span>
              </span>
              <span>
                <span class="label">纬度</span>
                <span class="data">{{
                  info.DroneLatitude | formatPosition
                }}</span>
              </span>
            </div>
          </div>
          <!-- 警航执照信息 -->
          <!-- <div class="title">
            <div class="text">人员信息</div>
          </div>
          <div class="parameter">
            <div class="flyer_info_1 flex">
              <img :src="info.head_icon_path">
              <div>
                <p>{{info.name}}</p>
                <span>{{info.teamName}}</span>
              </div>
            </div>
            <div class="flyer_info_2 flex">
              <div style="margin-right:40px;width:90px">
                <span>账号</span>
                <p>{{info.account}}</p>
              </div>
              <div style="width:160px">
                <span>警航执照</span>
                <p>{{info.police_license}}</p>
              </div>
            </div>
            <div class="flyer_info_2">
              <span>电话号码</span>
              <p>{{info.mobile_phone}}</p>
            </div>
          </div> -->
        </div>
      </div>
      <!--直播-->
      <div class="live" v-if="showLive">
        <div class="video">
          <!-- <div class="video-zoom">
            <div @click.stop="videoZoom(1)"></div>
            <div @click.stop="videoZoom(2)"></div>
            <div @click.stop="videoZoom(3)"></div>
            <div @click.stop="videoZoom(4)"></div>
          </div> -->
          <div class="video-box" ref="videoBox">
            <video-item
              :config="info"
              :isWatch="false"
              v-if="info.IsPublish"
              ref="liveVideo"
            />
          </div>
        </div>
        <div class="video-contorl">
          <!-- <div class="icon"> -->
          <!-- <Icon v-show="" type="ios-play" @click.stop="togglePlayLive" /> -->
          <!-- <Icon type="ios-play" @click.stop="togglePlayLive" /> -->
          <!-- </div> -->
          <!-- <div class="center"> -->
          <!-- <Icon type="md-volume-up" /> -->
          <!-- </div> -->
          <div
            class="icon"
            v-show="showSwitchChat"
            @click.stop="toggleChatModel"
          >
            <img :src="icons.chat" alt />
          </div>
          <div class="icon" @click.stop="toggleFull">
            <img :src="icons.screen" />
          </div>
        </div>
      </div>
      <div class="chat" v-show="!hideChatModel">
        <!--聊天-->
        <div
          class="inner-box-wrapper"
          v-if="showChat"
          v-show="curRightControlType === 'chat'"
        >
          <div class="msg-box" ref="chatBox">
            <div
              class="chat-item"
              v-for="(chat, index) in info.msgList"
              :key="index"
              :class="chat.role === 'self' ? 'on-right' : 'on-left'"
            >
              <div class="chat-avatar">
                <img
                  :src="
                    chat.role === 'self'
                      ? chat.avatar
                      : info.head_icon_path || chat.avatar
                  "
                  alt
                />
              </div>
              <div
                class="chat-content"
                :class="chat.msg_type === 'normal' ? '' : 'min-width'"
              >
                <div
                  class="chat-content-TeamMsg"
                  v-if="info.isTeam && chat.role !== 'self'"
                >
                  {{ chat.name }} :
                </div>
                <div
                  class="chat-content-msg"
                  v-if="chat.msg_type === 'normal' || !chat.msg_type"
                >
                  {{ chat.content }}
                </div>
                <div v-if="chat.msg_type === 'voice'" class="msg-voice pointer">
                  <!-- <Voice-Item :config="chat"
                              
                              :isClick="chat.isPlayingVoice"
                              @stopOtherAudio="stopOtherAudio" /> -->
                  <Voice-Item :config="chat" />
                </div>
                <span class="msg-triangle"></span>
              </div>
            </div>
          </div>
          <div class="btns-box">
            <img
              class="i-voice"
              v-if="showSendVoice"
              :src="icons.voice"
              @click="toggleWindowModel('sendVoice')"
              title="发送语音"
            />
            <img
              v-if="showRemoteControl"
              class="i-dash-board"
              :src="icons.chat_control"
              @click="toggleWindowModel('remoteControl')"
              title="远程控制"
            />
          </div>
          <div class="input-box" v-if="showSendMsg">
            <textarea
              v-model="sendTextMsgOptions.text"
              @keyup.enter="sendTextMsg('enter')"
            ></textarea>
            <Button
              :loading="sendTextMsgOptions.loading"
              @click.stop="sendTextMsg"
              class="send_out"
              >发送</Button
            >
          </div>
        </div>
        <!--語音-->
        <div class="voice" v-if="SendVoice" @click.stop="">
          <img src="../../../assets/img/statistics/send_recording.png" />
          <p>按住空格键开始录音</p>
          <p>{{ recordTime }} s</p>
          <img
            src="../../../assets/img/statistics/send_back.png"
            alt="关闭"
            @click.stop="destroyRecorder()"
          />
        </div>

        <!--远程控制-->
        <div
          v-show="
            curRightControlType === 'remoteControl' &&
            getControlStatus.text !== '已接受指挥控制'
          "
          class="ControlInitiate"
          style="width: 100%; height: 100%; background-color: #000"
        >
          <div class="back" v-show="hideBackChat" @click="curRightControlType = 'chat'">
            <img :src="icons.back" alt="" />
          </div>
          <div class="ControlInitiate_content">
            <img src="../../../assets/img/Icon/paper_plane.png" alt="" />
            <Button
              @click.native.stop="sendControlRequest('control')"
              :loading="getControlStatus.loading"
              class="flex"
              >{{ getControlStatus.text }}</Button
            >
            <Button
              :disabled="getControlStatus.disabled"
              @click.native.stop="sendControlRequest('stop')"
              class="flex"
              >解除远程指挥控制</Button
            >
            <div class="tip">
              确保双方网络环境稳定 无人机周围无复杂地形 与飞手保持沟通
            </div>
          </div>
        </div>
        <!-- 远程控制台 -->
        <div
          v-if="showRemoteControl"
          class="control voice-common inner-box-wrapper shadow-inner-bottom"
          v-show="
            curRightControlType === 'remoteControl' &&
            getControlStatus.text === '已接受指挥控制'
          "
        >
          <div class="inner-box control-one">
            <button
              @click.stop="remoteControlHandler(13)"
              style="margin-right: 26px"
            >
              一键起飞
            </button>
            <button
              @click.stop="remoteControlHandler(15)"
              style="display: none"
            >
              一键返航
            </button>
            <button @click.stop="sendControlRequest('stop')" class="logout">
              退出
            </button>
          </div>
          <div class="inner-box control-two">
            <h2>云台</h2>
            <div class="pan-tilt-box">
              <div>
                <button class="operation-btn">俯仰角</button>
                <br />
                <span>{{ info.gimbalPitch | formatAngle0 }}</span>
              </div>
              <div>
                <button class="operation-btn">水平角</button>
                <br />
                <span>{{ info.gimbalYaw | formatAngle0 }}</span>
              </div>
              <div class="four-direction">
                <div
                  class="center-circle pointer"
                  title="云台回中"
                  @click.stop="remoteControlHandler(11)"
                ></div>
                <div class="center-line rotate-clockwise"></div>
                <div class="center-line rotate-anti-clockwise"></div>
                <!--控制左摇杆上下左右-->
                <div
                  class="control-direction-arrow icon-top flex pointer"
                  @click.stop="remoteControlHandler(7, 'up')"
                >
                  <Icon type="md-arrow-dropup" />
                </div>
                <div
                  class="control-direction-arrow icon-right flex pointer"
                  @click.stop="remoteControlHandler(7, 'right')"
                >
                  <Icon type="md-arrow-dropup" />
                </div>
                <div
                  class="control-direction-arrow icon-bottom flex pointer"
                  @click.stop="remoteControlHandler(7, 'down')"
                >
                  <Icon type="md-arrow-dropup" />
                </div>
                <div
                  class="control-direction-arrow icon-left flex pointer"
                  @click.stop="remoteControlHandler(7, 'left')"
                >
                  <Icon type="md-arrow-dropup" />
                </div>
              </div>
            </div>

            <h2>云台速度</h2>
            <div class="slider">
              <Slider
                v-model="remoteContorlOptions.speed"
                show-tip="never"
                :max="10"
                :min="1"
                :step="1"
                @on-change="remoteControlHandler(16)"
              ></Slider>
            </div>
          </div>
          <div class="inner-box control-three">
            <div class="rocker-top">
              <h2 style="margin-bottom: 6px">控制台</h2>
              <Select v-model="ControlMode" size="small" style="width: 100px">
                <Option
                  v-for="item in ControlModeData"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </div>
            <div class="four-direction">
              <div class="center-circle"></div>
              <div class="center-line rotate-clockwise"></div>
              <div class="center-line rotate-anti-clockwise"></div>
              <!--控制左摇杆上下左右-->
              <div
                class="control-direction-arrow icon-top flex pointer"
                @click.stop="remoteControlHandler(2, 'L_up')"
              >
                <Icon type="md-arrow-dropup" />
              </div>
              <div
                class="control-direction-arrow icon-right flex pointer"
                @click.stop="remoteControlHandler(2, 'L_right')"
              >
                <Icon type="md-arrow-dropup" />
              </div>
              <div
                class="control-direction-arrow icon-bottom flex pointer"
                @click.stop="remoteControlHandler(2, 'L_down')"
              >
                <Icon type="md-arrow-dropup" />
              </div>
              <div
                class="control-direction-arrow icon-left flex pointer"
                @click.stop="remoteControlHandler(2, 'L_left')"
              >
                <Icon type="md-arrow-dropup" />
              </div>
            </div>

            <div class="four-direction">
              <div class="center-circle"></div>
              <div class="center-line rotate-clockwise"></div>
              <div class="center-line rotate-anti-clockwise"></div>
              <!--控制右摇杆上下左右-->
              <div
                class="control-direction-arrow icon-top flex pointer"
                @click.stop="remoteControlHandler(2, 'R_up')"
              >
                <Icon type="md-arrow-dropup" />
              </div>
              <div
                class="control-direction-arrow icon-right flex pointer"
                @click.stop="remoteControlHandler(2, 'R_right')"
              >
                <Icon type="md-arrow-dropup" />
              </div>
              <div
                class="control-direction-arrow icon-bottom flex pointer"
                @click.stop="remoteControlHandler(2, 'R_down')"
              >
                <Icon type="md-arrow-dropup" />
              </div>
              <div
                class="control-direction-arrow icon-left flex pointer"
                @click.stop="remoteControlHandler(2, 'R_left')"
              >
                <Icon type="md-arrow-dropup" />
              </div>
            </div>
          </div>
          <!-- 喊话器 -->
          <div class="speak-control flex">
            <div class="microphone">
              <img v-show="recordVoiceState === 'stoped'" :src="icons.yuyin" />
              <img
                v-show="recordVoiceState === 'recording'"
                :src="icons.yuyining"
              />
              <p style="font-size: 12px">
                按住空格键开始录音<br />（需御2喊话器）
              </p>
            </div>
            <div class="v-local-file">
              <div style="padding-bottom: 5px">
                <div
                  class="select-file"
                  v-if="!uploadFileInfos.show"
                  @click.stop="callUpload"
                >
                  选择本地音频文件
                </div>

                <input
                  v-if="!uploadFileInfos.show"
                  style="display: none"
                  type="file"
                  ref="upload"
                  @change="selectfileToUpload"
                />
                <div
                  class="file-detail flex pointer"
                  v-show="uploadFileInfos.show"
                >
                  <div class="info flex">
                    {{ uploadFileInfos.name }}
                    <pre>&nbsp;&nbsp;</pre>
                    ({{ uploadFileInfos.timeLength | formatVoiceLength }}
                    <pre>&nbsp;&nbsp;</pre>
                    {{ uploadFileInfos.size | formatFileSize }})
                    <pre>&nbsp;&nbsp;</pre>
                  </div>
                  <img
                    class="clear-info flex"
                    :src="icons.refresh"
                    @click.stop="clearFileInfo"
                    title="重新选择"
                  />
                </div>
              </div>
              <Button
                :loading="speakControlOptions.isUploading"
                class="voice-upload"
                @click.native.stop="submitUpload"
              >
                <span>上传</span>
              </Button>
            </div>
          </div>
          <div class="inner-other">
            <h2>相机</h2>
            <div class="flex">
              <div
                class="opretate-circle pointer"
                @click.stop="remoteControlHandler(5)"
              ></div>
              <p>拍照</p>
            </div>
            <div class="flex">
              <div
                class="opretate-circle pointer"
                @click.stop="remoteControlHandler(6)"
                style="background: #f00"
              ></div>
              <p>
                {{ remoteContorlOptions.reCordingStatus ? '结束' : '录像' }}
              </p>
            </div>
          </div>
        </div>

        <Spin size="large" fix v-if="recordOptions.isUploading"></Spin>
      </div>
      <!--聊天-->
    </div>
  </Modal>
</template>

<script>
// import CloseDash from "@/assets/img/statistics/close-dash.png";
import voice from '@/assets/img/statistics/send_voice.png'
// import voice from "@/assets/img/statistics/voice.png";
import dash_board from '@/assets/img/statistics/dash_board.png'
// import chat_control from "@/assets/img/statistics/chat-control.png";
import chat_control from '@/assets/img/statistics/send_control.png'
// import megaphone from "@/assets/img/statistics/megaphone.png";
import ava1 from '@/assets/img/statistics/ava1.png'
import ava2 from '@/assets/img/statistics/ava2.png'
import chat from '@/assets/img/statistics/live_chat.png'
import yuyin from '@/assets/img/statistics/yuyin.png'
import yuyining from '@/assets/img/statistics/yuyining.png'
import screen from '@/assets/img/statistics/live_screen.png'
// import full from "@/assets/img/statistics/full.png";
// import fewer from "@/assets/img/statistics/fewer.png";
import dashboard from '@/assets/img/statistics/dash-board.png'
import dashboardfold from '@/assets/img/Icon/shrink.png'
// import reControlAngle from "@/assets/img/statistics/re-control-angle.png";
// import reControlLevel from "@/assets/img/statistics/re-control-level.png";
import breakControl from '@/assets/img/Icon/breakControl.png'
import back from '@/assets/img/Icon/back.png'
import refresh from '@/assets/img/statistics/rerun.png'
import filterMixin from '@/utils/filter.mixin.js'
import VoiceItem from './voice-item.vue'
import VideoItem from './video-item.vue'
import Api from '@/utils/api.js'
import Recorder from 'js-audio-recorder'
import Socket from '@/utils/socket.mixin.js'
const lamejs = require('lamejstmp')

export default {
  name: 'chat-modal',
  props: {
    showModal: {
      type: Boolean,
      default: () => false,
    },
    info: {
      type: Object,
      default: () => {},
    },
    operations: {
      type: Array,
      require: false,
      default: () => {
        // 可以配置展示哪几种功能按钮
        return [
          'dash-board',
          'live',
          'chat',
          'send-voice',
          'remote-control',
          'speak-control',
        ]
      },
    },
  },
  components: { VoiceItem, VideoItem },
  mixins: [filterMixin, Socket],
  watch: {
    showModal(val) {
      // 切换聊天框
      this.show = val
      this.curRightControlType = 'chat'
      this.hideDash = true
      console.log(this.info.msgList)
      // this.resetModalStatus();
      setTimeout(() => {
        this.scrollToBottom()
      }, 400)
    },
    'info.msgList.length': {
      deep: true,
      handler: function (newV, oldV) {
        // console.log('info信息更新', newV);
        if (this.show) {
          setTimeout(() => {
            this.scrollToBottom()
          }, 400)
        }
      },
    },
    keyDownState(val) {
      if (!val) {
        setTimeout(() => {
          this.keyDownState = true
        }, 1000)
      }
    },
    curRightControlType(val) {
      //控制是否开启键盘监控
      console.log('当前展示的功能界面', val)
      if (val == 'chat') {
        this.addKeyDownEvent(false)
        if (this.recorder) {
          this.recorder.destroy()
          this.recorder = ''
        }
      } else if (val == 'sendVoice' || val == 'remoteControl') {
        this.addKeyDownEvent(true)
      }
    },
    // // 开启直播后重置缩放
    // 'info.IsPublish': {
    //   deep: false,
    //   handler: function (newV) {
    //     if (newV) {
    //       setTimeout(() => {
    //         this.isZoom = true;
    //         this.videoZoom(1);
    //       }, 300);
    //     }
    //   },
    // },
  },
  data() {
    return {
      hideBackChat: false,
      showSwitchChat: true,
      show: false,
      icons: {
        // closeDash: CloseDash,
        voice,
        dash_board,
        // megaphone,
        chat,
        yuyin,
        yuyining,
        dashboard,
        dashboardfold,
        // full,
        // fewer,
        screen,
        chat_control,
        // reControlAngle,
        // reControlLevel,
        refresh,
        back,
        breakControl,
      },
      closeDash: true,
      hideDashIcon: false,
      fullScreen: false,
      hideChatModel: false,
      recordVoiceState: 'stoped', // stoped recording
      uploadFileInfos: {
        name: '',
        size: '0M',
        timeLength: '0s',
        show: false,
        blob: null,
      },
      sendTextMsgOptions: {
        text: '',
        loading: false,
      },
      remoteContorlOptions: {
        speed: 1,
        reCordingStatus: false, // 'false' 结束 'true' 正在录制
      },
      speakControlOptions: {
        isUploading: false,
      },
      recordOptions: {
        hadPermission: false,
        isUploading: false,
        hadToast: false,
      },
      curRightControlType: 'chat', // chat sendVoice remoteControl speakControl
      pX: 0, //云台x
      pY: 0, //云台y
      lX: 0, //左遥控x
      lY: 0, //左遥控y
      rX: 0, //右遥控x
      rY: 0, //右遥控y
      ControlMode: '0',
      ControlModeData: [
        {
          value: '0',
          label: '美国手',
        },
        {
          value: '1',
          label: '日本手',
        },
      ],
      keyDownState: true,
      SendVoice: false,
      SpeakControl: false,
      recordTime: 0, // 语言时长
      isZoom: false, // 是否开启直播缩放
    }
  },

  mounted() {
    this.show = this.showModal
    this.addKeyDownEvent(false)
    // this.initRecorder();
    console.log('发送聊天消息页面-群聊-私聊', this.show)
  },
  beforeDestroy() {
    // this.destroyRecorder();
    this.cancelHandle()
    if (this.recorder) {
      this.recorder.destroy()
    }
  },
  computed: {
    // 控制是否展示这个功能按钮 dashboard面板
    showDashBoard() {
      return this.operations.indexOf('dash-board') !== -1
    },
    // 控制是否展示这个功能按钮 直播
    showLive() {
      return this.operations.indexOf('live') !== -1
    },
    // 控制是否展示这个功能 聊天窗口
    showChat() {
      return this.operations.indexOf('chat') !== -1
    },
    // 控制是否展示这个功能按钮 语音
    showSendVoice() {
      return this.operations.indexOf('send-voice') !== -1
    },
    // 控制是否展示这个功能按钮 远程控制
    showRemoteControl() {
      return this.operations.indexOf('remote-control') !== -1
    },
    // 控制是否展示这个功能按钮 喊话控制
    showSpeakControl() {
      return this.operations.indexOf('speak-control') !== -1
    },
    // 控制是否展示这个功能按钮 发送文字消息
    showSendMsg() {
      return this.operations.indexOf('send-msg') !== -1
    },
    // 根据页面状态返回样式 0.全屏 1. 正常情况都显示 2.没有直播其他功能有 3.只有聊天
    fullClass() {
      let cssClass = ''
      if (this.info.IsPublish) {
        cssClass = 'normal-wh'
      }
      if (this.info.IsPublish && !this.fullScreen) {
        cssClass = 'normal-wh'
      }
      if (this.info.IsPublish && this.fullScreen) {
        cssClass = ''
      }
      if (!this.info.IsPublish) {
        cssClass = 'only-chat'
      }
      if (!this.showLive) {
        // 只有聊天框 直播列表那边
        cssClass = 'only-chat'
      }
      return cssClass
    },
    // 用户信息
    userInfo() {
      return JSON.parse(window.sessionStorage.getItem('user_info') || '{}')
    },
    // 页面展示等待喊话控制的文案
    getSpeakControlStatus() {
      let status = {
        text: '请求远程喊话控制',
        loading: false,
        disabled: true,
      }
      // if (this.info.airPlaneAcceptedSpeakControlStatus === "waitingAccept") {
      //   status.text = "等待接收喊话控制";
      //   status.loading = true;
      //   status.disabled = false;
      // }
      // if (this.info.airPlaneAcceptedSpeakControlStatus === "accepted") {
      //   status.text = "已接受喊话控制";
      //   status.loading = false;
      //   status.disabled = false;
      // }
      return status
    },
    // 页面展示远程控制的文案
    getControlStatus() {
      // console.log(
      //   "页面展示远程控制的文案",
      //   this.info.airPlaneAcceptedControlStatus
      // );
      let status = {
        text: '远程控制无人机',
        loading: false,
        disabled: true,
      }
      if (this.info.airPlaneAcceptedControlStatus === 'waitingAccept') {
        status.text = '等待接收指挥控制'
        status.loading = true
        status.disabled = false
      }
      if (this.info.airPlaneAcceptedControlStatus === 'accepted') {
        status.text = '已接受指挥控制'
        status.loading = false
        status.disabled = false
      }
      // console.log(this.info.airPlaneAcceptedControlStatus);
      return status
    },
  },

  methods: {
    // 转换音频成mp3格式
    convertToMp3(wavDataView) {
      // 获取wav头信息
      const wav = lamejs.WavHeader.readHeader(wavDataView) // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
      const { channels, sampleRate } = wav
      const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128)
      // 获取左右通道数据
      const result = this.recorder.getChannelData()
      const buffer = []

      const leftData =
        result.left &&
        new Int16Array(result.left.buffer, 0, result.left.byteLength / 2)
      const rightData =
        result.right &&
        new Int16Array(result.right.buffer, 0, result.right.byteLength / 2)
      const remaining = leftData.length + (rightData ? rightData.length : 0)

      const maxSamples = 1152
      for (let i = 0; i < remaining; i += maxSamples) {
        const left = leftData.subarray(i, i + maxSamples)
        let right = null
        let mp3buf = null

        if (channels === 2) {
          right = rightData.subarray(i, i + maxSamples)
          mp3buf = mp3enc.encodeBuffer(left, right)
        } else {
          mp3buf = mp3enc.encodeBuffer(left)
        }
        if (mp3buf.length > 0) {
          buffer.push(mp3buf)
        }
      }

      const enc = mp3enc.flush()

      if (enc.length > 0) {
        buffer.push(enc)
      }

      return new Blob(buffer, { type: 'audio/mp3' })
    },
    // // 切换播放音频
    // togglePlayLive() {
    //   // console.log(this.$refs["liveVideo"].player);
    //   this.$refs['liveVideo'].player.videoPlay();
    //   this.info.isPlayingLive = !this.info.isPlayingLive;
    // },
    // 初始化录音器
    initRecorder() {
      this.recorder = new Recorder({
        sampleBits: 16000, // 采样位数，支持 8 或 16，默认是16
        sampleRate: 44100, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1, // 声道，支持 1 或 2， 默认是1
      })
    },
    // test(e) {
    //   console.log('Received Message你好: ' + e.data);
    // },
    // 点击发送 发送聊天消息
    sendTextMsg(type) {
      let msg = this.sendTextMsgOptions.text.trim()
      //   let msg2 = this.sendTextMsgOptions.text.trim();
      if (!msg) return
      //   if (type == 'enter') {
      //     msg = this.sendTextMsgOptions.text.substr(0, msg.length - 1);
      //   } else {
      //     msg = this.sendTextMsgOptions.text;
      //   }
      this.sendTextMsgOptions.text = ''
      this.sendTextMsgOptions.loading = true

      //   this.info.msgList 聊天框里自己发的信息
      if (this.info.isTeam) {
        //发送团队信息
        let params = {
          type: 'team_chat',
          msg_type: 'normal',
          msg_content: msg,
          voice_time: 0,
          team_id: this.userInfo.team_id,
          head_icon: this.userInfo.head_icon,
        }
        console.log('发送的群聊22', params)
        this.$emit('sendSocketMsg', params)

        this.info.msgList.push({
          avatar: this.userInfo.head_icon,
          role: 'self',
          msg_type: 'normal',
          content: msg,
          isPlayingVoice: false,
          voiceTimeLength: 0,
          voiceId: this.info.msgList.length - 1,
        })
        // this.scrollToBottom();
        setTimeout(() => {
          this.scrollToBottom()
        }, 500)
        this.sendTextMsgOptions.loading = false
      } else {
        console.log('from-user---this.userInfo', this.userInfo)
        console.log('target-user---this.info', this.info)
        let params = {
          type: 'user_msg',
          msg_type: 'normal',
          msg: msg,
          from_id: this.userInfo.user_id,
          from_name: this.userInfo.name,
          target_id: this.info.user_id,
          target_name: this.info.name,
        }
        console.log('点击了聊天发送按钮', params)

        this.$emit('sendSocketMsg', params)

        this.info.msgList.push({
          avatar: this.userInfo.head_icon,
          role: 'self',
          msg_type: 'normal',
          content: msg,
        })
        setTimeout(() => {
          this.scrollToBottom()
        }, 500)
        this.sendTextMsgOptions.loading = false
      }
    },
    // 聊天框滚动到底部
    scrollToBottom() {
      let ele = this.$refs['chatBox']
      if (ele) {
        ele.scrollTop = ele.scrollHeight - ele.clientHeight
      }
    },
    // // 点击一个播放的时候 停下其他所有的音频
    // stopOtherAudio(id) {
    //   this.info.msgList.forEach((ele) => {
    //     console.log(ele.isPlayingVoice);
    //     ele.isPlayingVoice = ele.voiceId === id;
    //     console.log(ele.isPlayingVoice);
    //   });
    // },
    // 点击清除刷新按钮 把选择的文件信息清理掉
    clearFileInfo() {
      this.uploadFileInfos.name = ''
      this.uploadFileInfos.show = false
      this.uploadFileInfos.blob = null
      this.uploadFileInfos.size = 0
      this.uploadFileInfos.timeLength = 0
    },
    // 获取选择的音频文件的长度
    getMp3FileTimeLength(file) {
      let _this = this
      //获取录音时长
      var url = URL.createObjectURL(file)
      //经测试，发现audio也可获取视频的时长
      var audioElement = new Audio(url)
      var duration
      audioElement.addEventListener('loadedmetadata', function (_event) {
        _this.uploadFileInfos.timeLength = audioElement.duration
      })
    },
    // 美国手
    sendUSADataDirectionJoystick(LX, LY, RX, RY) {
      var YAW_JOYCONTROL_MAXSPEED_DEFAULT = 3 * 10 //旋转灵敏度
      var ROLL_JOYCONTROL_MAXSPEED_DEFAULT = 10 //横滚灵敏度
      var PITCH_JOYCONTROL_MAXSPEED_DEFAULT = 10 //俯仰灵敏度
      var VERTICAL_JOYCONTROL_MAXSPEED_DEFAULT = 2 //升降灵敏度

      let val = {
        pitch: RX * PITCH_JOYCONTROL_MAXSPEED_DEFAULT,
        roll: RY * ROLL_JOYCONTROL_MAXSPEED_DEFAULT,
        yaw: LX * YAW_JOYCONTROL_MAXSPEED_DEFAULT,
        throttle: LY * VERTICAL_JOYCONTROL_MAXSPEED_DEFAULT,
      }
      return val
    },
    // 日本手
    sendJapanDataDirectionJoystick(LX, LY, RX, RY) {
      var YAW_JOYCONTROL_MAXSPEED_DEFAULT = 3 * 10 //旋转灵敏度
      var ROLL_JOYCONTROL_MAXSPEED_DEFAULT = 5 //横滚灵敏度
      var PITCH_JOYCONTROL_MAXSPEED_DEFAULT = 5 //俯仰灵敏度
      var VERTICAL_JOYCONTROL_MAXSPEED_DEFAULT = 2 //升降灵敏度

      let val = {
        pitch: RX * PITCH_JOYCONTROL_MAXSPEED_DEFAULT,
        roll: LY * ROLL_JOYCONTROL_MAXSPEED_DEFAULT,
        yaw: LX * YAW_JOYCONTROL_MAXSPEED_DEFAULT,
        throttle: RY * VERTICAL_JOYCONTROL_MAXSPEED_DEFAULT,
      }
      return val
    },
    // 远程控制处理函数
    remoteControlHandler(type, val) {
      this.pX = 0 //云台x
      this.pY = 0 //云台y
      this.lX = 0 //左遥控x
      this.lY = 0 //左遥控y
      this.rX = 0 //右遥控x
      this.rY = 0 //右遥控y
      if (this.info.airPlaneAcceptedControlStatus === 'none') {
        // if(){
        //
        // }
        // console.log(type);
        this.$notice.warning({
          title: '请先发送无人机控制请求!!',
        })
        return
      }
      if (this.info.airPlaneAcceptedControlStatus === 'waitingAccept') {
        this.$notice.warning({
          title: '正在等待无人机接受控制!',
        })
        return
      }
      // 远程控制信息
      let wsParams = {
        type: 'control',
        target_id: this.info.user_id,
        target_name: this.info.name,
        from_id: this.userInfo.user_id,
        from_name: this.userInfo.name,
        dataType: type,
        ...(type === 6
          ? { isRecord: !this.remoteContorlOptions.reCordingStatus }
          : {}),
      }
      // 控制遥感
      if (type === 2) {
        if (val == 'L_up') {
          this.lY = 1
        } else if (val == 'L_left') {
          this.lX = -1
        } else if (val == 'L_right') {
          this.lX = 1
        } else if (val == 'L_down') {
          this.lY = -1
        } else if (val == 'R_up') {
          this.rY = 1
        } else if (val == 'R_left') {
          this.rX = -1
        } else if (val == 'R_right') {
          this.rX = 1
        } else if (val == 'R_down') {
          this.rY = -1
        }
        if (this.ControlMode === '0') {
          //美国手
          let USAData = this.sendUSADataDirectionJoystick(
            this.lX,
            this.lY,
            this.rX,
            this.rY
          )
          wsParams = Object.assign(wsParams, USAData)
        } else {
          let JapanData = this.sendJapanDataDirectionJoystick(
            this.lX,
            this.lY,
            this.rX,
            this.rY
          )
          wsParams = Object.assign(wsParams, JapanData)
        }
      }
      // 录制视频指令
      if (type === 6) {
        this.remoteContorlOptions.reCordingStatus =
          !this.remoteContorlOptions.reCordingStatus
      }
      // 云台控制指令
      if (type === 7) {
        if (val == 'up') {
          this.pY = 3
        } else if (val == 'left') {
          this.pX = -3
        } else if (val == 'right') {
          this.pX = 3
        } else if (val == 'down') {
          this.pY = -3
        }
        wsParams.pX = this.pX
        wsParams.pY = this.pY
      }
      // 云台速度
      if (type === 16) {
        wsParams.speed = this.remoteContorlOptions.speed
      }
      // 云台回中
      if (type === 11) {
        wsParams.speed = this.remoteContorlOptions.speed
      }
      //发送远程控制
      this.$emit('sendSocketMsg', wsParams)
    },
    // 申请远程控制
    sendControlRequest(type) {
      // console.log("申请远程控制", type);
      // console.log("info数据", this.info);
      let wsParams = {
        type: 'control',
        target_id: this.info.user_id,
        target_name: this.info.name,
        from_id: this.userInfo.user_id,
        from_name: this.userInfo.name,
        dataType: type === 'control' ? 0 : 1,
      }
      this.$emit('sendSocketMsg', wsParams)
      this.info.airPlaneAcceptedControlStatus =
        type === 'control' ? 'waitingAccept' : 'none'
      // console.log(this.info.airPlaneAcceptedControlStatus);
      if (type === 'stop') {
        // this.resetModalStatus()
        this.toggleWindowModel('remoteControl')
      }
    },
    // 申请喊话控制
    sendSpeakControlRequest(type) {
      let wsParams = {
        type: 'speak_control',
        target_id: this.info.user_id,
        target_name: this.info.name,
        from_id: this.userInfo.user_id,
        from_name: this.userInfo.name,
        dataType: type === 'control' ? 0 : 3,
      }
      this.$emit('sendSocketMsg', wsParams)
      this.info.airPlaneAcceptedSpeakControlStatus =
        type === 'control' ? 'waitingAccept' : 'none'
    },
    // 选择本地文件准备上传--喊话
    selectfileToUpload(e) {
      let _this = this
      let file = e.target.files[0]
      this.uploadFileInfos.name = file.name
      this.getMp3FileTimeLength(file)
      let reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = function (res) {
        let blob = new Blob([res.target.result])
        _this.uploadFileInfos.blob = blob
        _this.uploadFileInfos.show = true
        _this.uploadFileInfos.size = blob.size / 1024 // byte => kb
      }
    },
    // 发送本地音频文件消息--喊话
    submitUpload() {
      // if (this.info.airPlaneAcceptedSpeakControlStatus === "none") {
      //   this.$notice.warning({
      //     title: "请先发送无人机控制请求!",
      //   });
      //   return;
      // }
      // if (this.info.airPlaneAcceptedSpeakControlStatus === "waitingAccept") {
      //   this.$notice.warning({
      //     title: "正在等待无人机接受控制!",
      //   });
      //   return;
      // }
      if (!this.uploadFileInfos.blob) {
        this.$notice.warning({
          title: '请选择音频文件!',
        })
        return
      }
      this.speakControlOptions.loading = true
      this.commonSendVoiceMsgHandler(
        this.uploadFileInfos.blob,
        this.uploadFileInfos.timeLength
      )
    },
    // 模拟点击文件上传--喊话
    callUpload() {
      if (!this.$refs['upload']) {
        this.$notice.warning({
          title: '请先清除已选文件!',
        })
        return
      }
      this.$refs['upload'].click()
    },
    // 添加键盘监听事件
    addKeyDownEvent(add = true) {
      if (add) {
        window.addEventListener('keydown', this.keyDownHandler)
        window.addEventListener('keyup', this.keyUpHandler)
      } else {
        window.removeEventListener('keydown', this.keyDownHandler)
        window.removeEventListener('keyup', this.keyUpHandler)
      }
    },
    // 监听键盘按下
    keyDownHandler(e) {
      let _this = this

      function sendHandler(type, val) {
        if (!_this.keyDownState) {
          return
        }
        _this.remoteControlHandler(type, val)
        _this.keyDownState = false
      }

      switch (e.keyCode) {
        case 32: //空格键
          //   console.log('进入录音');
          //   console.log(this.curRightControlType);
          if (
            this.curRightControlType !== 'sendVoice' &&
            this.curRightControlType !== 'remoteControl' &&
            this.SendVoice !== true
          ) {
            console.log('进入录音0')
            return
          }

          if (this.recordVoiceState === 'recording') return
          if (!this.recordOptions.hadPermission) return
          if (this.recordOptions.isUploading) {
            if (!this.recordOptions.hadToast) {
              this.$notice.warning({
                title: '请等待消息发送完毕!',
              })
              this.recordOptions.hadToast = true
            }
            return
          }
          this.recordVoiceState = 'recording'
          let _that = this
          //获取录音时长，这个可全局监听--喊话
          this.recorder.onprogress = function (params) {
            _that.recordTime = parseFloat(params.duration).toFixed(1)
          }

          this.recorder.start().then(
            () => {
              // 开始录音
              console.log('开始录音')
            },
            (error) => {
              // 出错了
              console.log(`${error.name} : ${error.message}`)
            }
          )
          break
        case 87: //左摇杆_上
          sendHandler(2, 'L_up')
          break
        case 83: //左摇杆_下
          sendHandler(2, 'L_down')
          break
        case 65: //左摇杆_左
          sendHandler(2, 'L_left')
          break
        case 68: //左摇杆_右
          sendHandler(2, 'L_right')
          break
        case 84: //右摇杆_上
          sendHandler(2, 'R_up')
          break
        case 71: //右摇杆_下
          sendHandler(2, 'R_down')
          break
        case 70: //右摇杆_左
          sendHandler(2, 'R_left')
          break
        case 72: //右摇杆_右
          sendHandler(2, 'R_right')
          break

        case 73: //云台_上
          sendHandler(7, 'up')
          break

        case 75: //云台_下
          sendHandler(7, 'down')
          break

        case 74: //云台_左
          sendHandler(7, 'left')
          break

        case 76: //云台_右
          sendHandler(7, 'right')
          break

        case 49: //一键起飞
          sendHandler(13)
          break

        case 50: //一键返航
          sendHandler(15)
          break

        case 51: //拍照
          sendHandler(5)
          break

        case 52: //录像
          sendHandler(6)
          break

        case 53: //录像断开
          sendHandler(6)
          break
      }
    },
    // 监听空格键放开
    keyUpHandler(e) {
      switch (e.keyCode) {
        case 32: //空格键
          if (
            this.curRightControlType !== 'sendVoice' &&
            this.curRightControlType !== 'remoteControl' &&
            this.SendVoice !== true
          ) {
            return
          }
          // if (this.curRightControlType === "speakControl") {
          // if (this.info.airPlaneAcceptedSpeakControlStatus === "none") {
          //   this.$notice.warning({
          //     title: "请先发送无人机控制请求!",
          //   });
          //   return;
          // }
          // if (
          //   this.info.airPlaneAcceptedSpeakControlStatus === "waitingAccept"
          // ) {
          //   this.$notice.warning({
          //     title: "正在等待无人机接受控制!",
          //   });
          //   return;
          // }
          // }
          if (!this.recordOptions.hadPermission) {
            this.$notice.error({
              title: '未获取到麦克风!',
            })
            return
          }

          if (this.recordOptions.isUploading) return

          this.recordTime = 0
          this.recordVoiceState = 'stoped'
          this.recorder.stop()
          let wavData = this.recorder.getWAV()
          let timeLength = Math.ceil(this.recorder.duration)
          let mp3BlobData = this.convertToMp3(wavData)
          this.recordOptions.isUploading = true
          this.commonSendVoiceMsgHandler(mp3BlobData, timeLength)
          break
      }
    },
    // 发送语音文件给接口--喊话
    commonSendVoiceMsgHandler(fileBlob = null, timeLength = 0) {
      console.log('放开录音空格', timeLength)
      let formData = new FormData()
      formData.append(
        'mpFile',
        fileBlob,
        'chat' + Date.parse(new Date()) + '.mp3'
      )
      if (this.info.isTeam) {
        formData.append('whether_mass', 'yes')
      } else {
        formData.append('whether_mass', 'no')
      }
      formData.append('token', sessionStorage.getItem('token'))
      this.$post(Api.uploadingVoiceFile(), formData)
        .then((res) => {
          if (res.code === 1) {
            if (this.info.isTeam) {
              //发送团队语音
              let msgParams = {
                type: 'team_chat', // 群聊
                msg_type: 'voice',
                msg_content: res.data.url,
                voice: res.data.url,
                voice_time: timeLength,
                team_id: this.info.team_id,
              }

              this.$emit('sendSocketMsg', msgParams)
              this.info.msgList.push({
                avatar: this.userInfo.head_icon,
                role: 'self',
                msg_type: 'voice',
                content: res.data.path + res.data.url,
                isPlayingVoice: false,
                voiceTimeLength: timeLength,
                voiceId: this.info.msgList.length - 1,
              })

              this.recordOptions.isUploading = false
              this.recordOptions.hadToast = false
              this.speakControlOptions.loading = false
            } else {
              let msgParams = {
                type: 'voice', // 私聊
                from_name: this.userInfo.name,
                from_id: this.userInfo.user_id,
                voice: res.data.url,
                voice_time: timeLength,
                target_id: this.info.user_id,
              }

              if (this.curRightControlType === 'chat') {
                this.$emit('sendSocketMsg', msgParams)

                this.info.msgList.push({
                  avatar: this.userInfo.head_icon,
                  role: 'self',
                  msg_type: 'voice',
                  content: res.data.path + res.data.url,
                  isPlayingVoice: false,
                  voiceTimeLength: timeLength,
                  voiceId: this.info.msgList.length - 1,
                })

                console.log('私聊的语音消息')

                this.recordOptions.isUploading = false
                this.recordOptions.hadToast = false
                this.speakControlOptions.loading = false
              } else if (this.curRightControlType === 'remoteControl') {
                // 发送远程喊话
                // 通过ws发送msgParams

                this.sendSpeakerRequest(msgParams)
                if (this.recorder) {
                  this.recorder.destroy()
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.$notice.error({
            title: '语音文件上传失败!',
            desc: err.toString(),
          })
          this.recordOptions.isUploading = false
          this.recordOptions.hadToast = false
          this.speakControlOptions.loading = false
        })
    },
    // ws发送喊话音频
    sendSpeakerRequest(data) {
      let wsParams = {
        type: 'send_Speaker',
        data: {
          msg_type: 1,
          target_type: 0,
          from_id: this.userInfo.user_id,
          msg_attribute: 0,
          time_line: new Date().getTime(),
          msg_content: data.voice,
          from_name: data.from_name,
          target_id: data.target_id,
        },
        // to_uid: this.info.user_id,
        // to_name: this.info.name,
        // from_uid: this.userInfo.user_id,
        // from_name: this.userInfo.name,
        // dataType: type === "control" ? 0 : 3,
      }
      // console.log(wsParams);
      this.$emit('sendSocketMsg', wsParams)
      this.recordOptions.isUploading = false
      this.recordOptions.hadToast = false
      this.speakControlOptions.loading = false
      this.clearFileInfo()
    },
    // 切换聊天框整体展示状态
    toggleChatModel() {
      this.hideChatModel = !this.hideChatModel
    },
    // 切换是否全屏
    toggleFull() {
      this.fullScreen = !this.fullScreen
      // 特警修改全屏（后期还需进一步修改）
    },
    // 同步是否隐藏了聊天框
    cancelHandle() {
      this.fullScreen = false
      this.resetModalStatus()
      this.$emit('cancel')
      //   if (this.recorder) {
      //     this.recorder.destroy();
      //     this.recorder = '';
      //   }
    },
    // 隐藏dashboard
    hideDashBoard() {
      if (!this.closeDash) {
        setTimeout(() => {
          this.hideDashIcon = !this.hideDashIcon
        }, 300)
      } else {
        this.hideDashIcon = !this.hideDashIcon
      }
      this.closeDash = !this.closeDash
    },
    // 切换当前展示的功能界面
    toggleWindowModel(type) {
      console.log('切换当前展示的功能界面', type)
      this.remoteContorlOptions.reCordingStatus = false // 重置录像指令
      if (type === 'sendVoice') {
        // 目前仅发送语言功能，未做判断
        this.SendVoice = true
        // 初始化录音器
        this.initRecorder()
        this.addKeyDownEvent(true)
        Recorder.getPermission().then(
          () => {
            console.log('给权限了')
            this.recordOptions.hadPermission = true
          },
          (error) => {
            this.$notice.error({
              title: '获取麦克风失败！',
              desc: `${error.name} : ${error.message}`,
            })
            // console.log(`${error.name} : ${error.message}`);
          }
        )
      } else if (type === 'remoteControl') {
        // 初始化录音器
        this.curRightControlType = type
        this.initRecorder()
        // this.addKeyDownEvent(true);
        Recorder.getPermission().then(
          () => {
            console.log('给权限了')
            this.recordOptions.hadPermission = true
          },
          (error) => {
            this.$notice.error({
              title: '获取麦克风失败！',
              desc: `${error.name} : ${error.message}`,
            })
            // console.log(`${error.name} : ${error.message}`);
          }
        )
      } else if (type === 'dash-board') {
        this.hideDashBoard()
      } else {
        this.curRightControlType = type
      }
    },
    // 重置modal状态到初始值
    resetModalStatus() {
      this.curRightControlType = 'chat'
      this.addKeyDownEvent(false)
      this.hideDash = true
    },
    destroyRecorder() {
      this.addKeyDownEvent(false)
      this.recorder.destroy()
      this.recorder = ''
      this.SendVoice = false
    },
    setModalFromWatch(type) {
      this.$nextTick(() => {
        this.toggleWindowModel(type)
      })
    },
    /**
     * 直播视频缩放
     */
    // videoZoom(type) {
    //   if (this.isZoom) {
    //     this.isZoom = false;
    //     this.$refs.videoBox.style.width = '100%';
    //     this.$refs.videoBox.style.height = '100%';
    //     // this.$refs.videoBox.style.transform = 'scale(1.0)';

    //     this.$refs.videoBox.style.top = '0';
    //     this.$refs.videoBox.style.left = '0';
    //     this.$refs.videoBox.style.bottom = 'auto';
    //     this.$refs.videoBox.style.right = 'auto';
    //   } else {
    //     this.isZoom = true;
    //     this.$refs.videoBox.style.width = '200%';
    //     this.$refs.videoBox.style.height = '200%';
    //     // this.$refs.videoBox.style.transform = 'scale(2.0)';
    //     switch (type) {
    //       case 1:
    //         this.$refs.videoBox.style.top = '0';
    //         this.$refs.videoBox.style.left = '0';
    //         this.$refs.videoBox.style.bottom = 'auto';
    //         this.$refs.videoBox.style.right = 'auto';
    //         break;
    //       case 2:
    //         this.$refs.videoBox.style.top = '0';
    //         this.$refs.videoBox.style.right = '0';
    //         this.$refs.videoBox.style.bottom = 'auto';
    //         this.$refs.videoBox.style.left = 'auto';
    //         break;
    //       case 3:
    //         this.$refs.videoBox.style.bottom = '0';
    //         this.$refs.videoBox.style.left = '0';
    //         this.$refs.videoBox.style.top = 'auto';
    //         this.$refs.videoBox.style.right = 'auto';
    //         break;
    //       case 4:
    //         this.$refs.videoBox.style.bottom = '0';
    //         this.$refs.videoBox.style.right = '0';
    //         this.$refs.videoBox.style.top = 'auto';
    //         this.$refs.videoBox.style.left = 'auto';
    //         break;
    //       default:
    //         break;
    //     }
    //   }
    // },
  },
}
</script>

<style lang="scss" scoped>
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.5s;
// }
// .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   opacity: 0;
// }
.normal-wh {
  /deep/ .ivu-modal-content {
    width: 1400px !important;
    height: 677px !important;
    // left: -100px;
    .content {
      height: 632px !important;
    }
  }
}
.only-chat {
  /deep/ .ivu-modal-content {
    width: 516px !important;
    height: 677px !important;
    // left: 0;
    .content {
      height: 632px !important;
    }
    .live {
      display: none !important;
    }
    .dash-board {
      display: none !important;
    }
  }
}

.i-voice {
  width: 37px;
  height: 37px;
}
.i-dash-board {
  width: 37px;
  height: 37px;
}
.i-megaphone {
  width: 37px;
  height: 37px;
}
.shadow-inner {
  box-shadow: inset 0px 9px 6px -6px rgba(0, 0, 0, 0.16),
    inset 0px -9px 6px -6px rgba(0, 0, 0, 0.16);
}
.shadow-inner-bottom {
  box-shadow: inset 0px 9px 6px -6px rgba(0, 0, 0, 0.16);
}
.shadow-inner-top {
  box-shadow: inset 0px -9px 6px -6px rgba(0, 0, 0, 0.16);
}
.modal {
  /deep/ {
    .ivu-upload-list {
      display: none;
    }
    .ivu-upload {
      line-height: 1;
      font-size: 12px;
      button {
        height: auto;
      }
    }
  }
  /deep/ .ivu-modal-content {
    // border-radius: pcRem(10);
    background-color: transparent;
    min-width: 520px;
    .ivu-modal-header {
      color: #fff;
      // border-radius: pcRem(10) pcRem(10) 0 0;
      width: 100%;
      height: 50px;
      // padding-bottom: pcRem(18);
      padding: 0 20px;
      font-size: 18px;
      border-bottom: 0;
      background-color: #2196f3;
      line-height: 50px;
      // display: flex;
      // align-items: center;
    }
    // .ivu-modal-close {
    //   top: 6px;
    // }
    .ivu-modal-close .ivu-icon-ios-close {
      color: #fff;
      font-size: 36px;
      top: -3px;
      // transform: scale(1.2);
      // font-weight: bold;
    }
    .ivu-modal-body {
      padding: 0;
      // border-radius: 0 0 pcRem(10) pcRem(10);
      top: 50px;

      .content {
        // width: pcRem(1000);
        // height: pcRem(504);
        height: 100%;
        position: relative;
        display: flex;
        align-items: stretch;
        background-color: transparent;
        .db-icon {
          position: absolute;
          top: 0;
          left: -23px;
          width: 23px;
          transform: rotate(180deg);
        }
        .dash-board {
          z-index: -1;
          transition: all 0.3s ease-in;
          // border-radius: pcRem(10) 0px 0px pcRem(10);
          position: absolute;
          top: -50px;
          left: -338px;
          width: 338px;
          // height: pcRem(450);
          background-color: #000;
          color: #fff;
          font-size: 12px;
          display: flex;
          flex-direction: column;

          .db-inner {
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            .db-fold {
              width: 23px;
              position: absolute;
              top: 45px;
              left: -23px;
            }
          }

          .title {
            height: 45px;
            width: 100%;
            padding: 0 20px;
            font-size: 18px;
            background-color: #212121;
            line-height: 45px;
            img {
              padding-top: 2px;
              cursor: pointer;
            }
          }
          .parameter {
            margin: 10px auto;
            width: 318px;
            background-color: #212121;
            .DRONE {
              display: flex;
              align-items: center;
              padding: 8px;
              img {
                width: 60px;
                margin-right: 16px;
              }
              span {
                font-size: 18px;
              }
            }
            .board {
              display: flex;
              flex-wrap: wrap;
              > div {
                width: 25%;
                height: 50px;
                text-align: center;
                line-height: 22px;
                span {
                  color: #6c6c6c;
                  font-size: 14px;
                }
                p {
                  font-size: 16px;
                }
              }
            }
            .flyer_info_1 {
              margin: 8px;
              height: 60px;
              justify-content: start;
              font-size: 14px;
              img {
                width: 60px;
                height: 60px;
                border-radius: 30px;
                margin-right: 16px;
              }
              p {
                font-size: 18px;
              }
            }
            .flyer_info_2 {
              margin: 8px 0 0 22px;
              font-size: 16px;
              justify-content: start;
              div {
                overflow: hidden;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
              span {
                font-size: 14px;
                color: #6c6c6c;
              }
            }
          }

          .location {
            width: 100%;
            padding: 0 20px;
            text-align: center;
            margin: 20px 0 25px;
            .label {
              font-size: 14px;
              color: #6c6c6c;
              margin-right: 10px;
            }
            .data {
              font-size: 16px;
            }
            // .lo-wrapper {
            //   // transform: scale(0.8);
            //   white-space: nowrap;
            //   span {
            //     white-space: nowrap;
            //     &:first-child {
            //       margin-right: pcRem(5);
            //     }
            //   }
            //   .label {
            //     margin-right: pcRem(5);
            //   }
            //   .data {
            //     margin-right: pcRem(2);
            //   }
            // }
          }
        }
        .hideDash {
          transition: all 0.3s ease-in;
          left: 0;
        }
        .live {
          display: inline-flex;
          width: 554px;
          flex: 1;
          height: 100%;
          flex-direction: column;
          //   background-color: #333;
          .video {
            width: 100%;
            // height: 592px;
            flex: 1;
            background-color: #fff;
            overflow: hidden;
            position: relative;
            // .video-zoom {
            //   width: 100%;
            //   height: 100%;
            //   position: absolute;
            //   z-index: 99;
            //   display: flex;
            //   flex-wrap: wrap;
            //   div {
            //     width: 50%;
            //     height: 50%;
            //   }
            // }
            .video-box {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 50;
            }
          }
          .video-contorl {
            height: 40px;
            background-color: #f4f4f4;
            display: flex;
            align-items: stretch;
            justify-content: flex-end;
            // flex: 0 0 pcRem(54);
            padding-left: 30px;
            padding-right: 20px;
            .icon {
              flex: 0 0 20px;
              font-size: 32px;
              cursor: pointer;
              display: flex;
              align-items: center;
              margin-right: 20px;
              &:last-child {
                margin-right: 0;
              }
            }
            img {
              cursor: pointer;
              width: 20px;
            }
            .center {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding-right: 20px;
              font-size: 32px;
              i {
                cursor: pointer;
              }
            }
          }
        }
        .chat {
          height: 100%;
          width: 366px;
          position: relative;
          .inner-box-wrapper {
            display: inline-flex;
            height: 100%;
            width: 100%;
            flex-direction: column;
            background-color: #f3f3f3;
            // border-radius: 0px 0px pcRem(10) 0px;
            overflow: hidden;
          }
          .msg-box {
            width: 100%;
            height: 70%;
            background-color: #e2e2e2;
            overflow-y: auto;
            padding: 10px;
            .chat-item {
              font-size: 16px;
              color: #000;
              display: flex;
              align-items: flex-start;
              padding: 10px;
              .chat-avatar {
                border-radius: 50%;
                overflow: hidden;
                height: 35px;
                margin: 0 10px;
                img {
                  width: 35px;
                  height: 35px;
                }
              }
              .min-width {
                min-width: 20%;
              }
              .chat-content {
                min-height: 44px;
                max-width: 50%;
                // flex: 0 0 50%;
                background-color: #fff;
                border-radius: 4px;
                padding: 10px;
                position: relative;
                .msg-triangle {
                  position: absolute;
                  color: #fff;
                  top: 4px;
                  width: 0;
                  height: 0;
                  border: 0 solid transparent;
                  border-top-width: 10px;
                  border-bottom-width: 10px;
                  border-right: 10px solid #fff;
                }
                // .msg-voice {
                // }
                .chat-content-TeamMsg {
                  font-size: 12px;
                  color: #999999;
                  margin-bottom: 4px;
                }
                .chat-content-msg {
                  word-break: break-all;
                }
              }
            }
            .on-left {
              .chat-content {
                .msg-triangle {
                  left: -6px;
                }
              }
            }
            .on-right {
              flex-direction: row-reverse;
              .chat-content {
                background-color: #96ed6b;
                .msg-triangle {
                  transform: rotate(180deg);
                  right: -6px;
                  border-right: 10px solid #96ed6b;
                }
              }
            }
          }
          .btns-box {
            display: flex;
            align-items: center;
            width: 100%;
            height: 62px;
            background-color: #fff;
            padding: 0 10px;
            img {
              margin-right: 24px;
              cursor: pointer;
            }

            div {
              flex: 1;
              display: flex;
              justify-content: flex-end;
              button {
                height: auto;
                padding: 2px 8px;
                font-size: 12px;
              }
            }
          }
          .input-box {
            width: 100%;
            height: calc(30% - 62px);
            position: relative;
            background-color: #fff;
            textarea {
              width: 100%;
              height: calc(100% - 30px);
              border-color: transparent;
              font-size: 18px;
              color: #000;
              padding: 0 15px;
              overflow-y: auto;
              resize: none;
              &:focus {
                outline-color: transparent;
              }
            }
            .send_out {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 78px;
              height: 30px;
              background: #2196f3;
              color: #fff;
              border-radius: 0;
            }
          }

          // voice
          .control {
            background-color: #212121;
            color: #fff;
            font-size: 16px;
            position: relative;
            h2 {
              font-size: 18px;
              margin-left: 4px;
            }
            .inner-box {
              display: flex;
              padding-left: 18px;
              justify-content: flex-start;
              background-color: #000;
              margin-bottom: 10px;
              .four-direction {
                width: 112px;
                height: 112px;
                border-radius: 50%;
                background-color: #212121;
                position: relative;

                .center-circle {
                  background-color: #afafaf;
                  top: 56px;
                  left: 56px;
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  position: absolute;
                  margin-left: -20px;
                  margin-top: -20px;
                  z-index: 5;
                }
                .center-line {
                  width: 114px;
                  height: 5px;
                  margin-top: -2px;
                  background-color: #000;
                  position: absolute;
                  top: 56px;
                  left: 0;
                }
                .rotate-clockwise {
                  transform: rotate(45deg);
                }
                .rotate-anti-clockwise {
                  transform: rotate(-45deg);
                }
                .control-direction-arrow {
                  color: #afafaf;
                  width: 48px;
                  height: 48px;
                  border-radius: 50%;
                  background-color: transparent;
                  position: absolute;
                }
                .icon-top {
                  top: 18px;
                  margin-top: -24px;
                  left: 56px;
                  margin-left: -24px;
                }
                .icon-bottom {
                  bottom: 18px;
                  margin-bottom: -24px;
                  left: 56px;
                  margin-left: -24px;
                  i {
                    transform: rotate(180deg);
                  }
                }
                .icon-right {
                  top: 56px;
                  margin-top: -24px;
                  right: 18px;
                  margin-right: -24px;
                  i {
                    transform: rotate(90deg);
                  }
                }
                .icon-left {
                  top: 56px;
                  margin-top: -24px;
                  left: 18px;
                  margin-left: -24px;
                  i {
                    transform: rotate(-90deg);
                  }
                }
              }
            }
            .control-one {
              height: 55px;
              align-items: center;
              position: relative;
              button {
                background-color: #2196f3;
                color: #fff;
                border-radius: 2px;
                padding: 4px 12px;
                cursor: pointer;
              }
              .logout {
                position: absolute;
                right: 35px;
                background-color: #e90000;
              }
            }
            .control-two {
              display: block;
              padding-top: 12px;
              padding-bottom: 12px;
              .pan-tilt-box {
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: space-around;
                button {
                  width: 88px;
                  height: 30px;
                  background: #212121;
                  color: #fff;
                  margin-bottom: 12px;
                }
              }
              .slider {
                padding: 0 26px 0 4px;
                /deep/ .ivu-slider-button-wrap {
                  width: 30px;
                  height: 30px;
                  top: -13px;
                  /deep/ .ivu-slider-button {
                    width: 30px;
                    height: 30px;
                    background-color: #fff;
                    border: 0;
                  }
                  /deep/ .ivu-slider-button:hover {
                    transform: scale(1.1);
                  }
                }
              }
            }
            .control-three {
              padding: 16px 0;
              align-items: center;
              justify-content: space-around;
              margin-bottom: 0;
            }
            .inner-other {
              background-color: #000;
              position: absolute;
              bottom: 0;
              width: 100%;
              padding: 18px 24px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .opretate-circle {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: #fff;
                margin-right: 18px;
                border: 3px solid #000;
                box-shadow: 2px 2px 0px #fff, 2px -2px 0px #fff,
                  -2px 2px 0px #fff, -2px -2px 0px #fff;
              }
            }
          }
          .voice {
            color: #fff;
            background-color: rgba(0, 0, 0, 0.7);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 90;
            font-size: 26px;
            width: 100%;
            height: 100%;
            // display: flex;
            // flex-direction: column;
            // align-items: center;
            text-align: center;
            & {
              display: block;
            }
            img:first-child {
              width: 152px;
              margin: 120px auto 42px;
            }
            img:last-child {
              width: 43px;
              margin: 178px auto 0;
            }
          }
          .ControlInitiate {
            width: 100%;
            height: 100%;
            background-color: #d6d6d6;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .back {
              position: absolute;
              top: 22px;
              left: 22px;
              cursor: pointer;
              z-index: 99;
            }
            .ControlInitiate_content {
              height: 100%;
              width: 100%;
              position: absolute;
              img {
                width: 107px;
                margin: 142px auto 100px;
              }
              button {
                margin: 20px auto;
                width: 200px;
                height: 43px;
                font-size: 18px;
                color: #fff;
                background-color: #212121;
                border-radius: 2px;
                font-weight: 400;
              }
              .tip {
                width: 176px;
                padding: 0 18px;
                margin: 90px auto 0;
                text-align: center;
                font-size: 14px;
                color: #646464;
                position: relative;
              }
              .tip::before {
                position: absolute;
                content: '';
                width: 176px;
                height: 1px;
                background-color: #4e4e4e;
                top: -8px;
                left: 0;
              }
              .tip::after {
                position: absolute;
                content: '';
                width: 176px;
                height: 1px;
                background-color: #4e4e4e;
                bottom: -8px;
                left: 0;
              }
            }
          }
          // 喊话器
          .speak-control {
            margin-top: 6px;
            background-color: #212121;
            text-align: center;
            .speak-control-title {
              margin-top: 50px;
            }
            .microphone {
              width: 45%;
              img {
                width: 30px;
                display: inline-block;
                margin: 0 auto;
              }
            }
            .v-local-file {
              width: 45%;
              .select-file {
                display: inline-block;
                padding: 3px 6px;
                border: 1px solid #ccc;
                border-radius: 5px;
                cursor: pointer;
                font-size: 14px;
              }
            }
            .btns-box {
              position: absolute;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}
.normal-wh {
  .chat {
    width: 516px;
  }
}
.only-chat {
  .chat {
    width: 100% !important;
  }
}
</style>

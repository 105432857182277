<template>
  <div
    class="live-video flex"
    id="live-video"
    ref="live_video"
    @dblclick="fullScreen = !fullScreen"
  >
    <!-- <video :id="videoId" class="video-js vjs-default-skin vjs-big-play-centered" width="400" height="300" preload="auto"></video> -->
    <!--<video class="video" :id="videoId"  style="background-color: #333333;width: 100%;height: 100%;"></video>-->
    <video
      class="video"
      :id="videoId"
      autoplay
      style="object-fit: contain; background-color: #333"
      @click.capture.stop=""
    ></video>
    <!-- <div class="video"
         :id="videoId"
         style="background-color: #333333; width: 100%; height: 100%"
         @click.capture.stop=""></div> -->
    <Spin size="large" fix v-if="spinShow"></Spin>

    <img
      class="FullscreenIcon"
      :src="fullScreen ? videoScreenRestore : videoFullscreen"
      alt=""
      @click="fullScreen = !fullScreen"
    />
  </div>
</template>

<script>
let _videoId = 0
import Api from '@/utils/api.js'
import videoFullscreen from '@/assets/img/statistics/videoFullscreen.png'
import videoScreenRestore from '@/assets/img/statistics/videoScreenRestore.png'

export default {
  name: 'video-item',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    isWatch: {
      type: Boolean,
    },
  },
  mounted() {
    console.log('指挥-无人机视频-video item page')
    this.init()
    var _this = this
    // document.addEventListener('fullscreenchange', function () {
    //   if (document.fullscreenElement != null) {
    //     console.info('Went full screen');
    //     _this.fullScreen = true;
    //   } else {
    //     console.info('Exited full screen');
    //     _this.fullScreen = false;
    //   }
    // });
    window.addEventListener('resize', () => {
      if (!this.isFullScreen()) {
        // 非全屏状态
        //业务逻辑
        this.fullScreen = false
      }
    })
  },
  watch: {
    config(val) {
      if (this.isWatch) {
        this.init()
      }
    },
    fullScreen(status) {
      console.log(status)
      // console.log(document.exitFullscreen);
      console.log(document.fullscreenElement)
      if (status) {
        var ele = this.$refs.live_video
        // var ele=document.getElementById('live-video');
        if (ele.requestFullscreen) {
          ele.requestFullscreen()
        } else if (ele.mozRequestFullScreen) {
          ele.mozRequestFullScreen()
        } else if (ele.webkitRequestFullScreen) {
          ele.webkitRequestFullScreen()
        }
      } else {
        var de = document
        if (de.exitFullscreen) {
          de.exitFullscreen()
        } else if (de.mozCancelFullScreen) {
          de.mozCancelFullScreen()
        } else if (de.webkitCancelFullScreen) {
          de.webkitCancelFullScreen()
        }
      }
    },
    FullscreenStatus(val) {
      console.log('FullscreenStatus', val)
    },
  },
  computed: {
    videoId() {
      return 'live_video_' + _videoId++
    },
    FullscreenStatus() {
      return (
        document.fullscreenElement ||
        document.msFullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        false
      )
    },
  },
  data() {
    return {
      spinShow: true,
      playUrl: '',
      canPlay: false,
      fullScreen: false,
      videoFullscreen,
      videoScreenRestore,
      player: null,
    }
  },
  methods: {
    //判断是否全屏
    isFullScreen() {
      return !!(document.webkitIsFullScreen || this.FullscreenStatus)
    },
    // 初始化video
    init() {
      var _this = this
      console.log(this.config)
      if (!(this.config.IsPublish || this.config.is_publish)) {
        return
      }
      this.$post(Api.getLiveUrl(), {
        client: 'web',
        user_id: this.config.user_id,
      })
        .then((res) => {
          console.log('<<<<<<<<<<<<<<直接item', res)
          if (res.data) {
            // if (this.player !== null) {
            //   this.player.destroy();
            // }
            // this.player = new TcPlayer(this.videoId, {
            //   // "m3u8": res.data.m3u8_url,
            //   flv: res.data.flv_url,
            //   h5_flv: true,
            //   //   rtmp: res.data.default_url,
            //   live: true,
            //   controls: 'none',
            //   autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
            //   width: '100%', //视频的显示宽度
            //   height: '100%', //视频的显示高度
            // });

            // ========
            ///////
            /**
             * flv.js
             * 离线的flv播放器
             * 不依赖flash
             * 目前作为通用flv播放器,取代tcplayer
             */
            if (this.player !== null) {
              this.player.pause()
              this.player.unload()
              this.player.detachMediaElement()
              this.player.destroy()
              this.player = null
            }
            this.$nextTick(() => {
              if (flvjs.isSupported()) {
                this.player = flvjs.createPlayer({
                  type: 'flv',
                  url: res.data.flv_url,
                  isLive: true,
                })
                this.player.attachMediaElement(
                  document.getElementById(_this.videoId)
                )
                this.player.load()
                this.player.play()
              } else {
                this.$message.error('不支持flv格式')
                return
              }
            })
            // ========

            // if (this.player !== null) {
            //   this.player.videoClear();
            //   this.player = null;
            // }
            // let videoObject = {
            //   container: '#' + this.videoId, //容器的ID或className
            //   variable: 'player', //播放函数名称
            //   autoplay: true,
            //   live: true,
            //   video: res.data.default_url,
            //   loaded: 'loadedHandler', //当播放器加载后执行的函数
            // };
            // this.player = new ckplayer(videoObject);
          }
        })
        .catch((err) => {
          console.log(err)
          this.$notice.error({
            title: '获取直播地址失败,返回无数据',
            desc: err.toString(),
          })
        })
        .finally(() => {
          this.spinShow = false
        })
    },
  },
  beforeDestroy() {
    // console.log("beforeDestroy", this.config.user_id);
    if (this.player !== null) {
      this.player.pause()
      this.player.unload()
      this.player.detachMediaElement()
      //////
      this.player.destroy()
      this.player = null
      /////
      //   this.player.videoClear();
    }
  },
}
</script>

<style lang="scss" scoped>
.live-video {
  width: 100%;
  height: 100%;
  position: relative;

  .video {
    width: 100%;
    height: 100%;
  }
  .FullscreenIcon {
    position: absolute;
    bottom: 30px;
    right: 10px;
    width: 20px;
    height: auto;
    opacity: 0;
    transition: opacity 1s;
    -webkit-transition: opacity 1s;
  }
}
.live-video:hover {
  .FullscreenIcon {
    opacity: 1 !important;
  }
}
</style>

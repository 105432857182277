import Api from "@/utils/api.js";

export default {
    name: 'websocket',
    data () {
        return {
            websocket: null,
            uid: '',
            Data: null
        }
    },
    // 绑定消息回调
    // socketOnMessageHandler (e) {
    //     // console.log('收到消息:' ,JSON.parse(e.data));
    //     let data
    //     try {
    //         // data = JSON.parse(e.data)
    //         data = JSON.parse(e.data.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r"))
    //         // if (data.type === 'init') {
    //         //     this.bindSocket(data)
    //         // } else {
    //         //     this.msgCb && this.msgCb(data);
    //         // }
    //         this.msgCb && this.msgCb(data);
    //     } catch (e) {
    //         console.log('格式错误：', e)
    //     }
    // },
    beforeDestroy () {
        console.log('mixin socket beforeDestroy');
        this.closeConnect()
    },
    computed: {
        userInfo () {
            return JSON.parse((window.sessionStorage.getItem('user_info') || '{}'))
        }
    },
    methods: {
        // 连接socket
        connectWebsocket (url, sendParam, msgCb) {
            console.log('ws建立连接');
            if (!url) {
                console.log('请传入正确的webscoket url')
                return
            }
            this.msgCb = msgCb;
            this.sendParam = sendParam;
            // 在此打印this.websocket,若存在,再判断this.websocket.readyState(CONNECTING OPEN CLOSING或CLOSED),如果已连接,则关闭
            if (this.websocket) {
                this.closeConnect()
            }
            try {
                this.websocket = new WebSocket(url);
                this.websocket.onopen = this.socketOnOpenHandler
                this.websocket.onclose = this.socketOnCloseHandler
                this.websocket.onmessage = this.socketOnMessageHandler
                this.websocket.onerror = this.socketOnErrorHandler
            } catch (e) {
                this.$Notice.error({
                    title: "websocket连接失败",
                    desc: e.toString()
                })
            }
        },
        // 发送自己的位置消息
        sendSelfInfo (interval = 2000) {
            if (this.websocket) {
                this.sendTimer = setInterval(() => {
                    this.websocket.send(
                        JSON.stringify(this.sendParam)
                    );
                    // this.websocket.send(
                    //     JSON.stringify({"type":"ping"})
                    // )
                }, interval)

            }
        },
        // 发送消息
        sendMessage (data) {
            console.log('mixin里的发送消息方法');
            if (this.websocket) {
                this.websocket.send(
                    JSON.stringify(data)
                )
            }
        },
        // 关闭连接
        closeConnect () {
            this.websocket && this.websocket.close();
            this.websocket = null
            this.uid = null
            this.sendTimer && clearInterval(this.sendTimer)
            this.sendTimer = null
        },
        socketOnOpenHandler (e) {
            console.log('建立连接');
        },
        socketOnCloseHandler (e) {
            console.log('连接关闭2');
        },
        // 绑定消息回调
        socketOnMessageHandler (e) {
            // console.log('收到消息12:' ,JSON.parse(e.data));
            this.msgCb && this.msgCb(JSON.parse(e.data));
            // try {
            //     // data = JSON.parse(e.data)
            //     // data = JSON.parse(e.data.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r"))
            //     // if (data.type === 'init') {
            //     //   this.bindSocket(data)
            //     // } else {
            //     //   this.msgCb && this.msgCb(data);
            //     // }
            //     this.msgCb && this.msgCb(data);
            // } catch (e) {
            //     console.log('格式错误：', e)
            // }
        },
        socketOnErrorHandler (e) {
            console.log("websocket通信发生错误！", e);
            this.$Notice.error({
                title: "websocket通信发生错误！",
                desc: e.toString()
            })
        },
        // 绑定socket
        bindSocket (data) {
            let param = {
                client: 'web',
                client_id: data.client_id,
                user_id: this.userInfo.user_id
            };
            this.$post(
                Api.bindWebSocketUrl(),
                param
            )
                .then(res => {
                    // console.log(res);
                    if (res.data) {
                        console.log('绑定websocket接口成功!');
                        this.uid = res.data.uid || '';
                        this.sendSelfInfo();
                    } else {
                        this.closeConnect();
                    }
                })
                .catch(err => {
                    this.$notice.error({
                        title: "绑定websocket接口失败！",
                        desc: err.toString()
                    });
                    this.closeConnect();
                })
        }
    }
}
